import update from 'immutability-helper';
import { createReducer } from 'redux-create-reducer';
import { combineBySystemRoles } from 'utils/helpers/combineBySystemRoles';
import { combineDevCenters } from 'utils/helpers/combineDevCenters';

import { authActionsTypes } from './actions';

const initialState = {
  userLogin: null,
  userLoginLoading: false,
  userVerification: null,
  userVerificationLoading: false,
  resetVerificationLoading: false,
  resetVerificationStatus: false,
  userGroup: '',
  userId: null,
  usersList: [],
  devcenters: {
    devCenters: [],
    devCentersBySystemRoleDD: [],
    devCentersBySystemRoleAuditors: [],
    byId: {},
    byIdFull: {},
    byIdShortName: {},
    byShortName: {},
    forSelectShortNames: [],
    forSelect: [],
    forSelectFull: [],
  },
  billingAgents: [],
  roles: [],
  customers: [],
  errors: {},
};

export default createReducer(initialState, {
  [authActionsTypes.SET_USER](
    state,
    { payload: { userGroup, userId } }
  ) {
    return update(state, {
      userId: {
        $set: userId,
      },
      userGroup: {
        $set: userGroup,
      },
    });
  },

  [authActionsTypes.SET_USER_SUCCESS](state, { payload: { usersList } }) {
    return update(state, {
      usersList: {
        $set: usersList,
      },
      activeUser: {
        $set: {
          ...usersList.find((user) => user.userId === state.userId),
          userGroup: state.userGroup,
        },
      },
    });
  },

  [authActionsTypes.GET_COMMON_DATA_SUCCESS](
    state,
    { payload: { devCenters, billingAgents, customers, devCentersBySystemRole, roles } }
  ) {
    const { byId, byIdFull, byShortName, byFullIdShortName, byIdShortName, forSelectShortNames, forSelect, forSelectFull } = combineDevCenters(devCenters);
    const { devCentersForDd, devCentersForAuditors } = combineBySystemRoles(devCentersBySystemRole);
    window.localStorage.setItem(
      'active-devcenters',
      JSON.stringify(byShortName)
    );

    return update(state, {
      devcenters: {
        $set: {
          devCenters,
          devCentersBySystemRoleDD: devCentersForDd,
          devCentersBySystemRoleAuditors: devCentersForAuditors,
          byId,
          byIdFull,
          byIdShortName,
          byFullIdShortName,
          byShortName,
          forSelectShortNames,
          forSelect,
          forSelectFull,
        },
      },
      billingAgents: {
        $set: billingAgents,
      },
      customers: {
        $set: customers,
      },
      roles: {
        $set: roles,
      },
    });
  },
  [authActionsTypes.USER_LOGIN](state) {
    return update(state, {
      userLoginLoading: {
        $set: true,
      },
    });
  },
  [authActionsTypes.USER_LOGIN_SUCCESS](
    state,
    { payload }
  ) {
    return update(state, {
      userLoginLoading: {
        $set: false,
      },
      userLogin: {
        $set: payload,
      },
      errors: {
        $unset: ['login'],
      },
    });
  },

  [authActionsTypes.USER_LOGIN_FAIL](
    state,
    { payload: { error } },
  ) {
    return update(state, {
      userLoginLoading: {
        $set: false,
      },
      errors: {
        login: {
          $set: error,
        },
      },
    });
  },

  [authActionsTypes.USER_VERIFICATION](state) {
    return update(state, {
      userVerificationLoading: {
        $set: true,
      },
    });
  },

  [authActionsTypes.USER_VERIFICATION_SUCCESS](
    state,
    { payload }
  ) {
    return update(state, {
      userVerificationLoading: {
        $set: false,
      },
      userVerification: {
        $set: payload,
      },
      errors: {
        $unset: ['verification'],
      },
    });
  },

  [authActionsTypes.USER_VERIFICATION_FAIL](
    state,
    { payload: { error } },
  ) {
    return update(state, {
      userVerificationLoading: {
        $set: false,
      },
      errors: {
        verification: {
          $set: error,
        },
      },
    });
  },

  [authActionsTypes.RESET_VERIFICATION](state) {
    return update(state, {
      resetVerificationLoading: {
        $set: true,
      },
    });
  },

  [authActionsTypes.RESET_VERIFICATION_SUCCESS](
    state,
    { payload },
  ) {
    return update(state, {
      resetVerificationLoading: {
        $set: false,
      },
      resetVerificationStatus: {
        $set: payload,
      },
      errors: {
        $unset: ['resetVerification'],
      },
    });
  },

  [authActionsTypes.RESET_VERIFICATION_FAIL](
    state,
    { payload: { error } },
  ) {
    return update(state, {
      resetVerificationLoading: {
        $set: false,
      },
      errors: {
        resetVerification: {
          $set: error,
        },
      },
    });
  },

  [authActionsTypes.USER_LOGOUT](state) {
    return update(state, {
      userLogin: {
        $set: null,
      },
      userVerification: {
        $set: null,
      },
      userId: {
        $set: null,
      },
      userGroup: {
        $set: '',
      },
    });
  },

  [authActionsTypes.REMOVE_ERROR](
    state,
    { payload }
  ) {
    return update(state, {
      errors: {
        $unset: [payload],
      },
    });
  },
});
