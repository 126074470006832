import React, {
  useCallback,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import { ActionButton } from 'components/buttons';

import Calendar from 'components/calendar';
import FilterSelect from 'components/filter-select';
import Controls from 'components/list-controls';
import GoTop from 'elements/go-top-button';
import ScreenPlaceholder from 'elements/screen-placeholder';
import StyledWrapper from 'elements/styled-wrapper';
import moment from 'moment';
import {
  format as formatDate,
  FORMATS,
} from 'utils/date';
import { autoScrollToTop } from 'utils/react';

import { TEXTS } from './locales/en';

import styles from './style.module.scss';

const getOptionValue = ({ value }) => value;
const getOptionLabel = ({ label }) => label;

export const UtilizationForMonthReport = ({
  location,
  devCenters: deliveryCenters,
  generateUtilizationForMonthReport,
}) => {
  autoScrollToTop(location);

  const [date, setDate] = useState(moment());

  const [deliveryCenter, setDeliveryCenter] = useState(undefined);
  const clickPDF = useCallback(
    () => {
      generateUtilizationForMonthReport({
        year: date.year(),
        month: date.month() + 1,
        devcenterIds: deliveryCenter ? deliveryCenter.map((devcenter) => devcenter.value) : Object.keys(deliveryCenters.byId),
      });
    },
    [generateUtilizationForMonthReport, date, deliveryCenter, deliveryCenters]
  );

  const pdfButton = (
    <ActionButton
      type="button"
      className={styles.pdfButton}
      onClick={clickPDF}
      dataId="generate-util-report-for-mounth"
    >
      {TEXTS.buttonTitle}
    </ActionButton>
  );

  return (
    <>
      <Controls
        title={TEXTS.controlsTitle}
        className={styles.controls}
      >
        <Calendar
          withStepControls
          withMonthSelecting
          minDetails="year"
          onChange={setDate}
          stepControlsConfig={{
            step: 1,
            unitName: 'month',
          }}
          value={date}
          popperProps={{
            placement: 'bottom-start',
          }}
        >
          <StyledWrapper>
            {formatDate(date, FORMATS.MMMMYYYY)}
          </StyledWrapper>
        </Calendar>
        <FilterSelect
          items={deliveryCenters.forSelect}
          selected={deliveryCenter}
          onChange={setDeliveryCenter}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          placeholder="All Delivery Centers"
          isMultiple
          withChips={false}
          placeholderLength="17.5rem"
        />
      </Controls>

      <ScreenPlaceholder
        description={TEXTS.description}
        content={<span>{pdfButton}</span>}
        isFull
      />

      <GoTop />
    </>
  );
};

UtilizationForMonthReport.propTypes = {
  devCenters: PropTypes.shape({}).isRequired,
  location: PropTypes.object.isRequired,
  generateUtilizationForMonthReport: PropTypes.func.isRequired,
};
