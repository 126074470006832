import React, { useMemo, useCallback, useEffect, Fragment } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Loader from 'components/loader';
import Filter from 'components/new-filter';
import { useFilter } from 'components/new-filter/hooks';
import { categoriesActions } from 'core/configurations/category-management/actions';
import { selectFormData, selectCategoriesFilterConfig } from 'core/configurations/category-management/selectors';
import { modalConductorActions } from 'core/modal-conductor/actions';
import { Form } from 'forms/categories-form';
import { get } from 'lodash';

import { formHeaders, emptyCategoryTemplate } from './configs';
import { NO_CATEGORIES_YET, NO_CONTENT_MATCHED_CRITERIA, DEFAULT_CATEGORY_KEYS } from './constants';
import { TabItemContainer } from './tab-item-container';

const {
  updateCategories,
  setCategoryType,
  changeCategoriesFilter,
  resetCategoriesFilter,
  resetErrors,
  deleteCategories,
} = categoriesActions;

export const TabItem = ({ tabData, id, type, creationType, categoriesNames }) => {
  const { filteredItems, isFiltering, isActive } = useFilter({
    items: tabData,
    selector: selectCategoriesFilterConfig,
  });
  const emptyFormMessage = isActive ? NO_CONTENT_MATCHED_CRITERIA : NO_CATEGORIES_YET;
  const {
    errors,
    isSubmitting,
    isFailed,
    categoriesKeys,
    hiqoCategoriesNames,
    hasUpdatePermissions,
    client,
  } = useSelector(selectFormData);
  const dispatch = useDispatch();

  // handlers
  const onUpdateCategories = useCallback((categories, categoryType) => dispatch(updateCategories({ categories, categoryType })), []);

  const onDeleteCategory = ({ key, name, categoryId }) => {
    dispatch(modalConductorActions.changeCurrentModal({
      currentModal: 'CONFIRM_ACTION_MODAL',
      params: {
        content: [
          {
            type: 'title',
            data: `Delete ${key} - ${name}?`,
          },
          {
            type: 'description',
            data: 'This record will be permanently deleted and cannot be restored.',
          },
        ],
        acceptActionName: 'deleteCategories',
        acceptActionTitle: 'Delete',
        acceptFunction: () => dispatch(deleteCategories({
          categories: [{
            categoryId,
          }],
          categoryType: creationType,
        })),
      },
    }));
  };

  const formData =
    useMemo(
      () => filteredItems.map((category) => ({
        key: category.key,
        name: category.name,
        isActive: !!category.isActive,
        isCreated: true,
        categoryId: get(category, 'clientCategoryId') || get(category, 'internalCategoryId'),
        isDisabled: Boolean(category.worklogs) || tabData.length === 1,
        isDeleteIconHidden: DEFAULT_CATEGORY_KEYS.includes(category.key),
      })),
      [
        tabData,
        filteredItems,
      ]
    );

  useEffect(() => {
    dispatch(setCategoryType({ categoryType: type }));
    return () => {
      dispatch(resetCategoriesFilter());
      dispatch(resetErrors());
    };
  }, []);

  return (
    <Fragment key={id}>
      <TabItemContainer>
        <Filter changeFilter={changeCategoriesFilter} />
        {isFiltering ?
          <Loader /> : (
            <Form
              formData={formData}
              type={creationType}
              formHeaders={formHeaders}
              onDeleteCategory={onDeleteCategory}
              onUpdateCategories={onUpdateCategories}
              categoriesKeys={categoriesKeys}
              categoriesNames={categoriesNames}
              hiqoCategoriesNames={hiqoCategoriesNames}
              emptyFormMessage={emptyFormMessage}
              emptyFormTemplate={emptyCategoryTemplate}
              isSubmitting={isSubmitting}
              isFailed={isFailed}
              hasUpdatePermissions={hasUpdatePermissions}
              errors={errors}
              client={client}
            />
          )}
      </TabItemContainer>
    </Fragment>
  );
};

TabItem.propTypes = {
  tabData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  creationType: PropTypes.string.isRequired,
  categoriesNames: PropTypes.arrayOf(PropTypes.string),
};

TabItem.defaultProps = {
  categoriesNames: [],
};
