import {
  colorPrimary,
} from 'assets/styles/variables';
import {
  USERS_GROUPS,
} from 'core/auth/constants';
import { STORE_KEYS } from 'core/billing-reports/constants';

import { filtersTemplates } from './config/filters';

const { DELIVERY_OFFICE_ADMIN, DELIVERY_OFFICE_ADMIN_BY, ACC_TBS, ACC_WAW, ACC_NQZ } = USERS_GROUPS;

export const currentPayrollReportModelGetter = (userGroup) => {
  const isDOA = [DELIVERY_OFFICE_ADMIN, DELIVERY_OFFICE_ADMIN_BY, ACC_TBS, ACC_WAW, ACC_NQZ].includes(userGroup);

  return ({
    filtersTemplates,
    additionalDataGetter: () => ({
      controlsHeading: 'Current Payroll Report',
      staffFilterItems: [{
        value: null,
        label: 'All Staff',
      }, {
        value: STORE_KEYS.ADMIN_STAFF,
        label: 'Administrative',
      }, {
        value: STORE_KEYS.DEV_STAFF,
        label: 'Delivery Staff',
      }],
      calendarCssRules: `
          && {
            display: flex;
            min-width: unset;
            align-items: center;
            margin-right: 2.3rem;
            padding-top: 0;
            border: none;
            & path {
              fill: ${colorPrimary};
            }
            .calendar__buttons {
              .calendar-date-picker__header-control {
                margin-left: 0;
                margin-right: 0.8rem;
              }
            }
            .calendar__toggle-button {
              padding: 0.3rem 0.8rem 0 0.8rem;
            }
          }
        `,
      calendarItemCssRules: `
          padding-top: 0.3rem;
          font-size: 1.6rem;
          line-height: 1.7rem;
          color:  ${colorPrimary};
        `,
      controlsCssRules: `
          padding-top: 2rem;
          padding-bottom: 1.6rem;
          &&& {
            display: grid;
            grid-template-columns:${isDOA ? '1fr repeat(2, minmax(auto, 15.2rem))' : '1fr repeat(1, minmax(auto, 15.2rem))'};
            grid-column-gap: 2.4rem;
            grid-row-gap: 0.8rem;
            grid-auto-rows: minmax(3.2rem, auto);
            flex-direction: column;
            padding-top: 0.8rem;
            font-size: 1.5rem;
          }
        `,
      controlsWrapperCssRules: `
          display: flex;
          grid-area: 2 / 1 / 2 / 5;
        `,
      selectCssRules: `
          && {
            width: unset;
            margin-right: 3.2rem;
            color: ${colorPrimary};
            font-size: 1.6rem;
            .filter-select {
              width: auto;
              height: auto;
              &__multi-value {
                margin-top: 0;
              }
              &__multi-value__label {
                color: ${colorPrimary};
              }
              &__placeholder {
                padding-left: 0;
                color: ${colorPrimary};
                min-width: max-content;
              }
              &__control--is-focused {
                outline: none !important;
              }
              &__dropdown-indicator {
                path {
                  fill: ${colorPrimary};
                }
              }
            }
          }
        `,
      controls: [
        {
          type: 'action',
          data: 'PDF',
          withBody: true,
          show: true,
          onClick: ({ actions }) => {
            actions.generateCurrentPayrollReport();
          },
        }, {
          type: 'action',
          data: 'Excel',
          withBody: true,
          show: isDOA,
          onClick: ({ actions }) => {
            actions.generateCurrentPayrollExcelReport();
          },
        }],
    }),
    dataTemplate: [],
  });
};
