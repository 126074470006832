import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import Calendar from 'components/calendar';
import FilterSelect from 'components/filter-select';
import Controls from 'components/list-controls';
import Loader from 'components/loader';
import ToastContainer from 'components/toast';
import { USERS_GROUPS } from 'core/auth/constants';
import { resourceManagementReportLinkAccess } from 'core/auth/guaranteedAccessRoles';
import StyledWrapper from 'elements/styled-wrapper';
import Wrapper from 'elements/wrapper';

import { get } from 'lodash';
import moment from 'moment';
import { getHasPermissions } from 'utils/auth';
import { updateState } from 'utils/helpers/history';

import { showResourceLinkByDevCenter } from 'utils/helpers/link';

import { Navigation, ReportTable } from './components';
import { contractExpiration, endOfProbation, offboarding, onboarding } from './config';
import { locales } from './locales/locales';

import styles from './style.module.scss';

export const ResourceManagementReport = memo(({ devCenters, tabIndex, tabIndexToPath, getResourceManagementReport, resourceManagementReport, resourceManagementReportFetching, resourceManagementEntityName, userGroup, availableDevCentersForDd }) => {
  const isCTO = getHasPermissions(userGroup, [USERS_GROUPS.CTO]);
  const isOfficeAdmin = getHasPermissions(userGroup, [USERS_GROUPS.OFFICE_ADMIN]);
  const hasPermissionsToModule = getHasPermissions(userGroup, resourceManagementReportLinkAccess);
  const [currentTabIndex, setCurrentTabIndex] = useState(tabIndex);
  const [currentDate, setCurrentDate] = useState(moment());
  const [currentDevCenters, setCurrentDevCenters] = useState([]);

  const displayedDate = useMemo(() => currentDate.format('MMMM YYYY'), [currentDate]);
  const filteredDevCenters = useMemo(
    () => isOfficeAdmin && availableDevCentersForDd && availableDevCentersForDd.length ? availableDevCentersForDd.map((devcenter) => ({
      value: devcenter,
      label: devCenters.byIdShortName[devcenter],
    })) : devCenters.forSelect,
    [availableDevCentersForDd, devCenters, isOfficeAdmin]
  );

  const setTabIndexHandler = useCallback((index) => {
    updateState({ tabIndex: index }, tabIndexToPath[index]);
    setCurrentTabIndex(index);
  }, [tabIndex]);

  const setCurrentDevCentersHandler = useCallback((data) => setCurrentDevCenters(data), []);

  const getTableConfig = useCallback(() => {
    const settings = {
      locales,
      showResourceLinkByDevCenter,
      isCTO,
      availableDevCenters: availableDevCentersForDd,
      hasPermissions: hasPermissionsToModule,
    };
    switch (tabIndexToPath[currentTabIndex]) {
      case 'onboarding':
        return onboarding(get(resourceManagementReport, 'onboardingRows', []), settings);
      case 'end-of-probation':
        return endOfProbation(get(resourceManagementReport, 'endOfProbationRows', []), settings);
      case 'contract-expiration':
        return contractExpiration(get(resourceManagementReport, 'contractExpirationRows', []), settings);
      case 'offboarding':
        return offboarding(get(resourceManagementReport, 'offboardingRows', []), settings);
      default:
        return null;
    }
  }, [currentTabIndex, resourceManagementReport]);

  const renderNavigation = useCallback(() => Object.keys(resourceManagementReport || {}).reduce((accumulator, currentValue) => ({ ...accumulator,
    [currentValue]: {
      label: locales[currentValue].title,
      value: resourceManagementReportFetching ? 0 : get(resourceManagementReport, `${currentValue}.length`, 0),
      valuePrefix: locales.resources,
    } }), {}), [currentTabIndex, resourceManagementReport, resourceManagementReportFetching]);

  useEffect(() => {
    getResourceManagementReport({
      devcenterIds: currentDevCenters.map((devCenter) => devCenter.value),
      year: currentDate.year(),
      month: currentDate.month() + 1,
    });
  }, [currentDate, currentDevCenters]);

  return (
    <>
      <ToastContainer containerId={resourceManagementEntityName} />
      <Controls
        title={locales.resourceManagementReport}
        className={styles.controls}
      >
        <Calendar
          withStepControls
          withMonthSelecting
          minDetails="year"
          onChange={setCurrentDate}
          stepControlsConfig={{
            step: 1,
            unitName: 'month',
          }}
          value={currentDate}
          popperProps={{
            placement: 'bottom-start',
          }}
        >
          <StyledWrapper>
            {displayedDate}
          </StyledWrapper>
        </Calendar>
        <FilterSelect
          key="devCentersSelect"
          isMultiple
          items={filteredDevCenters}
          getOptionValue={(option) => option.value}
          getOptionLabel={(option) => option.label}
          onChange={setCurrentDevCentersHandler}
          placeholder={locales.filterPlaceholder}
          withChips={false}
          placeholderLength="17.5rem"
        />
      </Controls>
      <Navigation
        tabIndex={currentTabIndex}
        setTabIndex={setTabIndexHandler}
        data={renderNavigation()}
      />
      <Wrapper isGrow>
        {resourceManagementReportFetching ? <Loader /> : <ReportTable {...getTableConfig()} />}
      </Wrapper>
    </>
  );
});

ResourceManagementReport.propTypes = {
  devCenters: PropTypes.shape({
    forSelect: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  tabIndex: PropTypes.number.isRequired,
  tabIndexToPath: PropTypes.arrayOf(PropTypes.string).isRequired,
  getResourceManagementReport: PropTypes.func.isRequired,
  resourceManagementReport: PropTypes.shape({}),
  resourceManagementReportFetching: PropTypes.bool.isRequired,
  resourceManagementEntityName: PropTypes.string.isRequired,
  userGroup: PropTypes.string.isRequired,
  availableDevCentersForDd: PropTypes.arrayOf(PropTypes.number),
};

ResourceManagementReport.defaultProps = {
  resourceManagementReport: null,
  availableDevCentersForDd: null,
};
