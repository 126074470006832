import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { ActionButton } from 'components/buttons';
import Calendar from 'components/calendar';
import FilterSelect from 'components/filter-select';
import Controls from 'components/list-controls';
import { filesActions } from 'core/files/actions';
import GoTop from 'elements/go-top-button';
import ScreenPlaceholder from 'elements/screen-placeholder';
import moment from 'moment';
import {
  format as formatDate,
  FORMATS,
} from 'utils/date';
import { autoScrollToTop } from 'utils/react';

import styles from './style.module.scss';

const mapDispatchToProps = {
  generateTodayBillingReport: filesActions.generateTodayBillingReport,
};

const propTypes = {
  location: PropTypes.object.isRequired,
  devCenters: PropTypes.shape({
    forSelect: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })),
  }).isRequired,
  generateTodayBillingReport: PropTypes.func.isRequired,
};

const calendarPopperProps = {
  placement: 'bottom-start',
};

const getOptionValue = ({ value }) => value;
const getOptionLabel = ({ label }) => label;

const calendarStepControlsConfig = {
  step: 1,
  unitName: 'day',
};

const TodayBillingReport = ({
  location,
  devCenters: deliveryCenters,
  generateTodayBillingReport,
}) => {
  autoScrollToTop(location);

  const [date, setDate] = useState(moment());

  const [deliveryCenter, setDeliveryCenter] = useState([]);
  useEffect(
    () => setDeliveryCenter([]),
    [deliveryCenters]
  );

  const clickPDF = useCallback(
    () => {
      if (deliveryCenter != null) {
        generateTodayBillingReport({
          dateOfReport: formatDate(date),
          devcenterId: deliveryCenter.map((devcenter) => devcenter.value),
        });
      }
    },
    [generateTodayBillingReport, date, deliveryCenter]
  );

  const isPdfButtonActive = date.format('MM-DD-YYYY') > moment().format('MM-DD-YYYY') ? 'button--disabled' : 'button--cancel';

  const pdfButton = (
    <ActionButton
      type="button"
      className={styles.pdfButton}
      disabled={!isPdfButtonActive}
      onClick={clickPDF}
    >
      PDF
    </ActionButton>
  );

  return (
    <>
      <Controls
        title="Daily Resource Billings Report"
        className={styles.controls}
      >
        <Calendar
          withStepControls
          withMonthSelecting
          minDetails="month"
          onChange={setDate}
          value={date}
          popperProps={calendarPopperProps}
          stepControlsConfig={calendarStepControlsConfig}
        >
          <>
            {formatDate(date, FORMATS.MMDDYYYY)}
          </>
        </Calendar>

        <FilterSelect
          items={deliveryCenters.forSelect}
          selected={deliveryCenter}
          onChange={setDeliveryCenter}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          placeholder="All Delivery Centers"
          placeholderLength="17.7rem"
          withChips={false}
          // cssRules={selectCssRules}
          isMultiple
        />

      </Controls>
      <ScreenPlaceholder
        description={(
          <>
            <p>Please select Date and Delivery center and then generate a report.</p>
            <p>Please note, the Date cannot be greater than today&apos;s Date.</p>
          </>
        )}
        content={pdfButton}
        isFull
      />
      <GoTop />
    </>
  );
};

TodayBillingReport.propTypes = propTypes;

export default connect(
  null,
  mapDispatchToProps
)(TodayBillingReport);
