import React, { useCallback, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { ActionButton } from 'components/buttons';
import FilterSelect from 'components/filter-select';
import Controls from 'components/list-controls';

import ScreenPlaceholder from 'elements/screen-placeholder';

import { TEXTS } from './locales/en';
import styles from './style.module.scss';

const getOptionValue = ({ value }) => value;
const getOptionLabel = ({ label }) => label;

export const UtilizationForToday = ({ devCenters, generateUtilizationReport }) => {
  const [deliveryCenters, setDeliveryCenter] = useState([]);

  const handleSetDevCenters = (value) => {
    setDeliveryCenter(value);
  };

  const clickPDF = useCallback(
    () => {
      generateUtilizationReport({
        devcenterIds: deliveryCenters.map((devcenter) => devcenter.value),
      });
    },
    [generateUtilizationReport, deliveryCenters, devCenters]
  );

  const content = useMemo(() => (
    <ActionButton
      dataId="generate-report"
      type="button"
      onClick={clickPDF}
      className={styles.pdfButton}
    >
      {TEXTS.buttonTitle.toUpperCase()}
    </ActionButton>
  ), [deliveryCenters, devCenters]);

  return (
    <>
      <Controls
        title={TEXTS.controlsTitle}
        className={styles.controls}
      >
        <FilterSelect
          items={devCenters.forSelect}
          selected={deliveryCenters}
          onChange={handleSetDevCenters}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          placeholder="All Delivery Centers"
          isMultiple
          withChips={false}
          placeholderLength="17.5rem"
        />
      </Controls>
      <ScreenPlaceholder
        description={TEXTS.description}
        content={content}
        isFull
      />
    </>
  );
};

UtilizationForToday.propTypes = {
  devCenters: PropTypes.shape({
    forSelect: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  generateUtilizationReport: PropTypes.func.isRequired,
};
