import { USERS_GROUPS } from 'core/auth/constants';
import { FILTER_TYPES, STORE_KEYS } from 'core/configurations/category-management/constants';
import { getFilteredValues } from 'core/configurations/category-management/utils';
import { categoryManagementModel } from 'layouts/category-management/model';
import { createSelector } from 'reselect';
import { modelParser, filtersConfigGetter } from 'utils/helpers/models';

const { dataTemplate, rowStatusGetter, filtersTemplate, tableName } = categoryManagementModel();

export const selectClientsData = (state) => state.categories.clientsWithCategoriesData;
export const selectCountClientsData = (state) => state.categories.clientsWithCategoriesData.length;
export const selectIsFetching = (state) => state.categories.isFetching;
export const selectIsFiltering = (state) => state.categories.isFiltering;
export const selectIsCategoriesFiltering = (state) => state.categories.isCategoriesFiltering;
export const selectIsSubmitting = (state) => state.categories.isSubmitting;
export const selectErrors = (state) => state.categories.errors;
export const selectEntityName = (state) => state.categories.entityName;
export const selectFilters = (state) => state.categories.filters;
export const selectOrderRules = (state) => state.categories.orderRules;
export const selectClient = (state) => state.categories.client;
export const selectCategoryTypes = (state) => state.categories.categoryTypes;
export const selectCategoryType = (state) => state.categories.categoryType;
export const selectIsFormSubmitted = (state) => state.categories.isFormSubmitted;
export const selectIsFailed = (state) => state.categories.isFailed;
export const selectCategoriesFilter = (state) => state.categories[FILTER_TYPES.CATEGORIES_FILTER];
export const selectCategoriesKeys = (state) => state.categories.keys;
export const selectHiqoCategoriesNames = (state) => state.categories.clientsWithCategoriesData[0].names;
export const selectUserGroup = (state) => state.auth.userGroup;
export const selectIsOnlyActiveClients = (state) => state.categories.filters[FILTER_TYPES.CLIENTS_FILTER][STORE_KEYS.IS_ACTIVE_CLIENT];

// filters
export const selectClientsFilters = createSelector(selectFilters, (filters) => filters[FILTER_TYPES.CLIENTS_FILTER]);

export const selectCategoriesFilterConfig = createSelector(selectCategoriesFilter, selectIsCategoriesFiltering, (filter, isCategoriesFiltering) => ({
  filter,
  isFiltering: isCategoriesFiltering,
}));

// combined selectors
export const selectFormData = createSelector(
  [
    selectIsSubmitting,
    selectIsFormSubmitted,
    selectIsFailed,
    selectErrors,
    selectCategoriesKeys,
    selectCategoryType,
    selectClient,
    selectHiqoCategoriesNames,
    selectUserGroup,
  ],
  (
    isSubmitting,
    isFormSubmitted,
    isFailed,
    errors,
    keys,
    categoryType,
    client,
    hiqoCategoriesNames,
    userGroup,
  ) => {
    const categoriesKeys = [...keys.existingClientCategoryKeys, ...keys.existingInternalCategoryKeys];

    return {
      isSubmitting,
      isFormSubmitted,
      isFailed,
      hasUpdatePermissions: userGroup !== USERS_GROUPS.CPS,
      errors,
      categoriesKeys,
      hiqoCategoriesNames: !client.clientId ?
        hiqoCategoriesNames :
        [],
      client,
    };
  }
);
export const selectIsProcessing = createSelector(selectIsFetching, selectIsFiltering, (isFetching, isFiltering) => isFetching || isFiltering);
export const selectActiveClientsFilters = createSelector(selectClientsFilters, (filters) => {
  const activeFilters = Object.keys(filters)
    .reduce((activeFilter, key) => filters[key].isActive ? [...activeFilter, { key, value: filters[key].selected }] : activeFilter, []);
  const hasActiveFilters = activeFilters.some(({ value, key }) => key === STORE_KEYS.IS_ACTIVE_CLIENT ?
    !value :
    Boolean(value));

  return { activeFilters, hasActiveFilters };
});

export const selectClientsDataWithFilters = createSelector(
  selectClientsData,
  selectActiveClientsFilters,
  (clientsData, { activeFilters }) => getFilteredValues(clientsData, activeFilters)
);

export const selectClients =
    createSelector(
      [
        selectClientsDataWithFilters,
        selectOrderRules,
        selectIsProcessing,
        selectCountClientsData,
        selectIsFormSubmitted,
      ],
      (filteredClients, clientsTableOrderRules, isProcessing, countClients, isFormSubmitted) => {
        // order rules by table name
        const orderRules = clientsTableOrderRules[tableName];
        // parse data through model parser
        const { data } = modelParser(
          filteredClients,
          dataTemplate,
          {
            idKey: 'clientId',
            rowStatusGetter,
            filtersTemplate,
            orderRules,
          }
        );

        const countFilteredClients = data.length;

        return {
          data,
          countClients,
          countFilteredClients,
          orderRules,
          isProcessing,
          isFormSubmitted,
        };
      }
    );

export const selectClientTableFilters =
  createSelector(
    [
      selectClientsFilters,
      selectClients,
      selectActiveClientsFilters,
      selectIsOnlyActiveClients,
    ],
    (filters, { data: clients }, { hasActiveFilters }, isOnlyActiveClientsFilter) => {
      const filterConfig = filtersConfigGetter(filters, clients, { filtersTemplate });
      const filter = filterConfig.slice(0, 1);

      return ({
        filter,
        hasActiveFilters,
        isOnlyActiveClients: isOnlyActiveClientsFilter.isActive,
      });
    }
  );

// categories
export const selectCategories = createSelector(
  selectClient,
  selectCategoryTypes,
  ({ clientName, clientId, ...clientData }, categoryTypes) => ({
    clientId,
    clientName,
    categories: clientData,
    categoryTypes,
  })
);
