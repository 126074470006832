import React, { memo, useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import GroupController from 'components/expansion-panel/group-control-button';
import FilterSelect from 'components/filter-select';
import Controls from 'components/list-controls';
import Loader from 'components/loader';
import ToastContainer from 'components/toast';
import EmptyMessage from 'elements/empty-message';
import Wrapper from 'elements/wrapper';

import { isEmpty } from 'lodash';

import { ReportTable } from './components/report-table/report-table';
import styles from './style.module.scss';

const RequiredReviewsReport = memo(({ getRequiredReviewsReport, requiredReviewsReport, requiredReviewsReportFetching, requiredReviewsProjectLeads, requiredReviewsEntityName }) => {
  const [renderedData, setRenderedData] = useState([]);
  const setCurrentDevCentersHandler = useCallback((data) => {
    if (isEmpty(data)) {
      return setRenderedData(requiredReviewsReport);
    }
    const filteredArray = requiredReviewsReport.filter((obj) => data.some((filterObj) => filterObj.value === obj.projectLeadFullname));
    return setRenderedData(filteredArray);
  }, [requiredReviewsReport]);

  useEffect(() => {
    getRequiredReviewsReport();
  }, []);

  useEffect(() => {
    if (requiredReviewsReport) {
      setRenderedData(requiredReviewsReport);
    }
  }, [requiredReviewsReport]);

  const render = () => {
    if (isEmpty(renderedData)) {
      return <EmptyMessage description="This section currently contains no data." />;
    }

    return renderedData.map((reviewsWidget) => (
      <ReportTable key={reviewsWidget.projectLeadFullname} data={reviewsWidget.resourceData} label={reviewsWidget.projectLeadFullname} />
    ));
  };

  return (
    <>
      <ToastContainer containerId={requiredReviewsEntityName} />
      <Controls
        title="Required Reviews"
        className={styles.controls}
      >
        <FilterSelect
          key="devCentersSelect"
          isMultiple
          items={requiredReviewsProjectLeads}
          getOptionValue={(option) => option.value}
          getOptionLabel={(option) => option.label}
          onChange={setCurrentDevCentersHandler}
          placeholder="All Project Leads"
          placeholderLength="15.5rem"
          withChips={false}
        />

        <GroupController
          groupId="requiredReviews"
          className={styles.groupControl}
        />
      </Controls>
      <Wrapper isGrow className={styles.wrapper}>
        {
          requiredReviewsReportFetching ? <Loader /> : render()

        }
      </Wrapper>
    </>
  );
});

export default RequiredReviewsReport;

RequiredReviewsReport.propTypes = {
  requiredReviewsReport: PropTypes.array.isRequired,
  getRequiredReviewsReport: PropTypes.func.isRequired,
  requiredReviewsProjectLeads: PropTypes.array.isRequired,
  requiredReviewsReportFetching: PropTypes.bool.isRequired,
  requiredReviewsEntityName: PropTypes.string.isRequired,
};
