import React, {
  useCallback, useMemo,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import { ActionButton } from 'components/buttons';
import Calendar from 'components/calendar';
import FilterSelect from 'components/filter-select';
import Controls from 'components/list-controls';
import GoTop from 'elements/go-top-button';
import ScreenPlaceholder from 'elements/screen-placeholder';
import StyledWrapper from 'elements/styled-wrapper';
import { isEmpty } from 'lodash';
import moment from 'moment';
import {
  format as formatDate,
  FORMATS,
} from 'utils/date';
import { autoScrollToTop } from 'utils/react';

import { TEXTS } from './locales/en';

import styles from './style.module.scss';

const getOptionValue = ({ value }) => value;
const getOptionLabel = ({ label }) => label;

export const UtilizationForYearReport = ({
  location,
  devCenters: deliveryCenters,
  generateUtilizationForYearReport,
}) => {
  autoScrollToTop(location);

  const [date, setDate] = useState(moment());

  const [deliveryCenter, setDeliveryCenter] = useState([]);

  const clickPDF = useCallback(
    () => {
      generateUtilizationForYearReport({
        devcenterIds: !isEmpty(deliveryCenter) ? deliveryCenter.map((devcenter) => devcenter.value.toString()) : Object.keys(deliveryCenters.byId),
        year: date.year(),
      });
    },
    [generateUtilizationForYearReport, date, deliveryCenter, deliveryCenters]
  );

  const content = useMemo(() => (
    <ActionButton
      dataId="generate-report-for-year"
      type="button"
      onClick={clickPDF}
      className={styles.pdfButton}
    >
      {TEXTS.buttonTitle.toUpperCase()}
    </ActionButton>
  ), [generateUtilizationForYearReport, date, deliveryCenter, deliveryCenters]);

  return (
    <>
      <Controls
        title={TEXTS.controlsTitle}
        className={styles.controls}
      >
        <Calendar
          withStepControls
          withYearSelecting
          minDetails="decade"
          onChange={setDate}
          value={date}
          stepControlsConfig={{
            step: 1,
            unitName: 'year',
          }}
          popperProps={{
            placement: 'bottom-start',
          }}
        >
          <StyledWrapper>
            {formatDate(date, FORMATS.YYYY)}
          </StyledWrapper>
        </Calendar>

        <FilterSelect
          items={deliveryCenters.forSelect}
          selected={deliveryCenter}
          onChange={setDeliveryCenter}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          placeholder="All Delivery Centers"
          isMultiple
          withChips={false}
          placeholderLength="17.5rem"
        />
      </Controls>

      <ScreenPlaceholder
        description={TEXTS.description}
        content={content}
        isFull
      />

      <GoTop />
    </>
  );
};

UtilizationForYearReport.propTypes = {
  location: PropTypes.object.isRequired,
  devCenters: PropTypes.shape({}).isRequired,
  generateUtilizationForYearReport: PropTypes.func.isRequired,
};
