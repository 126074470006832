import gql from 'graphql-tag';
export const queryConfig = {
  userLogin: gql`
    query userLogin($email: String, $password: String) {
      UserLoginResponse: userLogin(email: $email, password: $password) {
        qrcode
        secretToken
        showInstructions
        otpToken
      }
  }`,
  userVerification: gql`
    query userVerification($otpToken: String, $numbers: [String]) {
      UserVerificationResponse: userVerification(otpToken: $otpToken, numbers: $numbers) {
        authVerified
        apiToken
      }
    }
  `,
  verificationReset: gql`
    query verificationReset($userEmail: String) {
      VerificationResetResponse: verificationReset(userEmail: $userEmail) {
        status
      }
    }
  `,
  getHolidays: gql`
    query getNonWorkingDays($year: Int) {
      nonWorkingDays(year: $year) {
        recordId
        devcenter
        date
        description
      }
    }
  `,
  getCommonData: gql`{
      users {
        email
        userId
        fullName
        isSales
      },
      roles {
        value: roleId
        label: roleName
      },
      devcenters {
        devcenterId
        name
        shortname
        active
      }
      devcentersBySystemRole {
        role
        devcenterList
      }
      billingAgents {
        value: billingAgentId
        label: name
        chipLabel: shortName,
      }
      customers {
        value
        label
      }
    }`,
};
