import React, { forwardRef, useState, useEffect, useCallback } from 'react';

import { useFormContext } from 'react-hook-form';

import PropTypes from 'prop-types';

import DeleteIcon from 'assets/img/icon-delete.svg';

import Switcher from 'forms/categories-form/components/switcher';
import { isEmptyPropsInCollection } from 'forms/categories-form/utils';
import { StyledFormArrayBox, StyledFieldArrayControls } from 'forms/styled-components';

import { DeleteButton } from '../buttons/delete-button';
import { CategoryKey } from '../category-key';
import { CategoryName } from '../category-name';

export const FieldArrayItem = forwardRef(({
  field,
  index,
  control,
  counter,
  removeAction,
  setRemovedFields,
  onDelete,
  hasUpdatePermissions,
}, ref) => {
  const { setValue, watch } = useFormContext();
  const category = watch('categories')[index];
  const { isCreated, isDisabled, isDeleteIconHidden } = field;
  const [blocker, setBlocker] = useState(false);
  const myRef = index === counter ? ref : null;

  const handleSwitcherClick = useCallback(() => {
    if (hasUpdatePermissions && field.isCreated) {
      setValue(`categories.${index}.isActive`, !category.isActive, { shouldDirty: true });
    }
  }, [
    category.isActive,
    isCreated,
    index,
    setValue,
  ]);

  const handleDeleteClick = useCallback(() => {
    setRemovedFields((prev) => [...prev, field]);
    removeAction(index);
  }, [index]);

  const handleDeleteCategoryClick = useCallback(() => {
    onDelete(field);
  }, [field]);

  useEffect(() => {
    const isEmptyCategoryProps = isEmptyPropsInCollection(category, ['key', 'name']);

    if (isEmptyCategoryProps && blocker) {
      setBlocker(false);
    }
  }, [
    category.name,
    category.key,
    blocker,
  ]);

  return (
    <StyledFormArrayBox ref={myRef}>
      <CategoryKey
        {...{ control, index, field }}
        viewMode={isCreated}
        setBlocker={setBlocker}
      />
      <CategoryName
        {...{ control, index, field }}
        viewMode={isCreated}
        isBlocked={blocker}
        hasUpdatePermissions={hasUpdatePermissions}
      />
      <StyledFieldArrayControls isDeleteIconHidden={isDeleteIconHidden}>
        <Switcher
          {...{ control, index, field }}
          onClick={handleSwitcherClick}
          isDisabled={!hasUpdatePermissions || !isCreated}
        />
        <DeleteButton
          onClick={isCreated ? handleDeleteCategoryClick : handleDeleteClick}
          icon={DeleteIcon}
          isDisabled={isDisabled && isCreated}
          withIcon={!isDeleteIconHidden}
        />
      </StyledFieldArrayControls>
    </StyledFormArrayBox>
  );
});

FieldArrayItem.propTypes = {
  field: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  control: PropTypes.object.isRequired,
  hasUpdatePermissions: PropTypes.bool,
  removeAction: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  setRemovedFields: PropTypes.func.isRequired,
  counter: PropTypes.number.isRequired,
};

FieldArrayItem.defaultProps = {
  hasUpdatePermissions: true,
};
