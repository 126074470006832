import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import ToastContainer from 'components/toast';

import {
  todayUtilizationReportAccess,
  ptoReportAccess,
  requiredReviewsReportAccess,
  resourceBookingReportAccess,
} from 'core/auth/guaranteedAccessRoles';
import { selectAvailableDevCentersForDd, selectDevCenters } from 'core/auth/selectors';
import { billingReportsActions } from 'core/billing-reports/actions';
import reducer from 'core/billing-reports/reducer';
import saga from 'core/billing-reports/sagas';

import {
  selectBillingProjectReportsDataModel,
  selectBillingProjectReportsDetails,
  selectCurrentTaskOrder,
  selectEntityName,
  selectIsChannelPartnerVersion,
  selectIsFetching,
  selectIsFetchingTaskOrdersList,
  selectOrderRules,
  selectParsedTaskOrderDetails,
  selectSelectedDevCenter,
  selectSelectedReportDate,
  selectSortedTaskOrdersData,
} from 'core/billing-reports/selectors';
import { commonActions } from 'core/common/actions';

import { filesActions } from 'core/files/actions';
import { requiredReviewsReportActions } from 'core/required-reviews-report/actions';
import {
  selectEntityName as selectEntityRequiredReviewsName,
  selectProjectLeads,
  selectRequiredReviewsReportData,
  selectRequiredReviewsReportFetching,
} from 'core/required-reviews-report/selectors';
import { resourceManagementReportActions } from 'core/resource-management-report/actions';
import {
  selectEntityName as selectEntityResourceManagementName,
  selectResourceManagementData,
  selectResourceManagementFetching,
} from 'core/resource-management-report/selectors';
import { useCheckPermissions } from 'hooks';
import InternalToReport from 'layouts/Internal-to-report';
import AnnualReport from 'layouts/annual-report';
import BillingProjectReports from 'layouts/billing-project-reports';
import { ContractFulfillmentReport } from 'layouts/contract-fulfillment-report';
import CurrentPayrollReport from 'layouts/current-payroll-report';
import DeliveryCenterUtilizationByMonth from 'layouts/delivery-center-utilization-by-month';
import { MentoringCompensationReport } from 'layouts/mentoring-compensation-report';
import ProjectLeadCommissionReport from 'layouts/project-leads-commission-report';
import { PtoReport } from 'layouts/pto-report';
import { RequiredReviewsReport } from 'layouts/required-reviews-report';
import { ResourceManagementReport } from 'layouts/resource-management-report';
import SalesReport from 'layouts/sales-report';
import BillingSummaryReports from 'layouts/summary-reports';
import TodayBillingReport from 'layouts/today-billing-report';
import { UtilizationForMonthReport } from 'layouts/utilization-for-month-report';
import { UtilizationForToday } from 'layouts/utilization-for-today-report';
import { UtilizationForYearReport } from 'layouts/utilization-for-year-report';
import { ResourceBookingReport } from "layouts/resource-booking-report";
import compose from 'lodash/fp/compose';
import moment from 'moment';
import { Redirect, Route, Switch } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { injectReducer, injectSaga } from 'utils/helpers/injectors';

const pathToTabIndex = {
  onboarding: 0,
  'end-of-probation': 1,
  'contract-expiration': 2,
  offboarding: 3,
};

const Reports = (props) => {
  const { setEntityName, entityName, ...restProps } = props;
  const { isAuthorizedToRoute } = useCheckPermissions(restProps);

  useEffect(() => {
    setEntityName(entityName);
  }, [entityName]);

  return (
    <>
      <ToastContainer containerId={entityName} />
      <Switch>
        <Route
          path={[
            '/reports/billing-reports/project/:reportType',
            // eslint-disable-next-line no-useless-escape
            '/reports/billing-reports/project/:reportType/:currentTO(d*)/?date',
          ]}
          render={({ location: { pathname } }) => {
            const [reportType, currentTO, date = ''] = pathname
              .replace('/reports/billing-reports/project/', '')
              .split('/');
            const [year, month] = date.split(':');
            const selectedDate = date ?
              moment({
                year,
                month: month - 1,
              }) :
              moment();
            return isAuthorizedToRoute ? (
              <BillingProjectReports
                reportType={reportType}
                currentTO={currentTO}
                selectedDate={selectedDate}
                {...props}
              />
            ) : null;
          }}
        />

        <Route
          path="/reports/billing-reports/summary/:reportType/:tab"
          render={({ match: { params } }) => {
            const { reportType, tab } = params;

            return isAuthorizedToRoute ? (
              <BillingSummaryReports
                reportType={reportType}
                tab={tab}
                {...props}
              />
            ) : null;
          }}
        />

        <Route
          path="/reports/billing-reports/internal-to"
          render={() => isAuthorizedToRoute ? <InternalToReport {...props} /> : null}
        />

        <Route
          path="/reports/sales-reports"
          render={() => isAuthorizedToRoute ? <SalesReport {...props} /> : null}
        />

        <Route
          path="/reports/annual-report/:tab"
          render={({ match: { params } }) => {
            const { tab } = params;

            return isAuthorizedToRoute ? (
              <AnnualReport tab={tab} {...props} />
            ) : null;
          }}
        />

        <Route
          path="/reports/current-payroll-report"
          render={() => isAuthorizedToRoute ? <CurrentPayrollReport {...props} /> : null}
        />

        <Route
          path="/reports/project-lead-commission"
          render={() => isAuthorizedToRoute ? (
            <ProjectLeadCommissionReport {...props} />
          ) : null}
        />

        <Route
          path="/reports/delivery/today-billing"
          render={() => isAuthorizedToRoute ? <TodayBillingReport {...props} /> : null}
        />

        <Route
          path="/reports/utilization-for-year"
          render={() => isAuthorizedToRoute ? <UtilizationForYearReport {...props} /> : null}
        />

        <Route
          path="/reports/utilization-for-month"
          render={() => isAuthorizedToRoute ? (
            <UtilizationForMonthReport {...props} />
          ) : null}
        />

        <Route
          path="/reports/utilization-by-month/:deliveryCenterIds?"
          render={({
            match: {
              params: { deliveryCenterIds },
            },
          }) => isAuthorizedToRoute ? (
            <DeliveryCenterUtilizationByMonth
              deliveryCenterIds={
                deliveryCenterIds &&
                  deliveryCenterIds.split(',').map((s) => Number(s))
              }
            />
          ) : null}
        />
        <Route
          path="/reports/todays-cfr-report"
          render={() => (
            isAuthorizedToRoute ? <ContractFulfillmentReport /> : null
          )}
        />
        <Route
          path="/reports/mentoring-compensation-report"
          render={() => (
            isAuthorizedToRoute ? <MentoringCompensationReport /> : null
          )}
        />

        <Route
          path="/reports/resource-management-report/:tab"
          render={({ match: { params } }) => {
            const { tab } = params;
            const tabIndex = pathToTabIndex[tab] || 0;
            return (
              isAuthorizedToRoute ? <ResourceManagementReport tabIndex={tabIndex} tabIndexToPath={Object.keys(pathToTabIndex)} {...props} /> : null
            );
          }}
        />

        <Route
          path={`/${requiredReviewsReportAccess.route}`}
          render={() => isAuthorizedToRoute ? <RequiredReviewsReport {...props} /> : null}
        />

        <Route
          path={`/${ptoReportAccess.route}`}
          render={() => isAuthorizedToRoute ? <PtoReport /> : null}
        />

        <Route
          path={`/${todayUtilizationReportAccess.route}`}
          render={() => isAuthorizedToRoute ? (
            <UtilizationForToday
              devCenters={props.devCenters}
              generateUtilizationReport={props.generateUtilizationReport}
            />
          ) : null}
        />

        <Route
          path={`/${resourceBookingReportAccess.route}`}
          render={() => isAuthorizedToRoute ? (
            <ResourceBookingReport
              devCenters={props.devCenters}
              generateBookingReport={props.generateBookingReport}
            />
          ) : null}
        />

        <Redirect to="/home" />
      </Switch>
    </>
  );
};

Reports.propTypes = {
  entityName: PropTypes.string.isRequired,
  userGroup: PropTypes.string,
  setEntityName: PropTypes.func.isRequired,
  guaranteedAccessRoles: PropTypes.arrayOf(PropTypes.string),
  guaranteedAccessRolesForSubRoutes: PropTypes.shape(),
  devCenters: PropTypes.shape({
    forSelect: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  generateUtilizationReport: PropTypes.func.isRequired,
  generateUtilizationForMonthReport: PropTypes.func.isRequired,
  generateUtilizationForYearReport: PropTypes.func.isRequired,
};

Reports.defaultProps = {
  userGroup: '',
  guaranteedAccessRoles: [],
  guaranteedAccessRolesForSubRoutes: null,
};

const mapStateToProps = createStructuredSelector({
  devCenters: selectDevCenters,
  orderRules: selectOrderRules,
  entityName: selectEntityName,
  availableDevCentersForDd: selectAvailableDevCentersForDd,
  isFetching: selectIsFetching,
  selectedDevCenter: selectSelectedDevCenter,
  selectedReportDate: selectSelectedReportDate,

  currentTaskOrder: selectCurrentTaskOrder,
  taskOrdersData: selectSortedTaskOrdersData,
  isFetchingTaskOrdersList: selectIsFetchingTaskOrdersList,
  taskOrderDetails: selectParsedTaskOrderDetails,
  billingProjectReportsDetails: selectBillingProjectReportsDetails,
  billingProjectReportsDataModel: selectBillingProjectReportsDataModel,
  resourceManagementEntityName: selectEntityResourceManagementName,
  resourceManagementReport: selectResourceManagementData,
  resourceManagementReportFetching: selectResourceManagementFetching,
  requiredReviewsReport: selectRequiredReviewsReportData,
  requiredReviewsEntityName: selectEntityRequiredReviewsName,
  requiredReviewsProjectLeads: selectProjectLeads,
  requiredReviewsReportFetching: selectRequiredReviewsReportFetching,
  isChannelPartnerVersion: selectIsChannelPartnerVersion,
});

const mapDispatchToProps = {
  setEntityName: commonActions.setEntityName,
  changeFilter: billingReportsActions.changeFilter,
  resetFilters: billingReportsActions.resetFilters,

  getHolidaysList: billingReportsActions.getHolidaysList,
  getTaskOrdersList: billingReportsActions.getTaskOrdersList,
  getTaskOrdersDetails: billingReportsActions.getTaskOrdersDetails,
  setProjectReportType: billingReportsActions.setProjectReportType,

  setSummaryReportType: billingReportsActions.setSummaryReportType,

  getBillingProjectReport: billingReportsActions.getBillingProjectReport,
  generateBillingProjectReport: filesActions.generateBillingProjectReport,
  generateBillingProjectExcelReport:
    filesActions.generateBillingProjectExcelReport,
  generateBillingProjectToExcelReport:
    filesActions.generateBillingProjectToExcelReport,
  generateUtilizationForMonthReport: filesActions.generateUtilizationForMonthReport,
  generateUtilizationForYearReport: filesActions.generateUtilizationForYearReport,
  generateBookingReport: filesActions.generateBookingReport,
  setBillingProjectReportData:
    billingReportsActions.setBillingProjectReportData,
  setBillingSummaryReportData:
    billingReportsActions.setBillingSummaryReportData,

  getResourceManagementReport: resourceManagementReportActions.getResourceManagementReport,
  getRequiredReviewsReport: requiredReviewsReportActions.getRequiredReviewsReport,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'billingReports', reducer });
const withSaga = injectSaga({ key: 'billingReportsSagas', saga });

export default compose(withReducer, withSaga, withConnect)(Reports);
