import React, { useState, useEffect, useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';

import { colorSecondaryGrayLight } from 'assets/styles/variables';
import classnames from 'classnames';
import Checkbox from 'elements/checkbox';
import { useEffectOnlyOnUpdate } from 'hooks';
import update from 'immutability-helper';
import ReactSelect, { components } from 'react-select';
import styled, { css } from 'styled-components';

import './styles.scss';

const checkboxStyles = `
  min-width: 20rem;
  margin: 2rem;
  font-size: 1.4rem;
  color: ${colorSecondaryGrayLight}
`;

const MenuList = (menuListProps) => {
  const {
    isMulti,
    hasValue,
    selectProps: {
      onClick,
      filterStatus,
      optionAllText,
    },
  } = menuListProps;

  return (
    <>
      {filterStatus.map((item) => (
        <Checkbox
          key={item.name}
          title={item.title}
          isActive={item.isActive}
          onClick={useCallback(() => onClick(item.name), [item])}
          cssRules={checkboxStyles}
        />
      ))}

      <components.MenuList
        {...menuListProps}
      >
        {
          isMulti && (
            <button
              className={classnames(
                'filter-select__option',
                'filter-select__option--all',
                { 'filter-select__option--is-selected': !hasValue }
              )}
              onClick={() => {
                if (hasValue) {
                  menuListProps.clearValue();
                }
                window.document.activeElement.blur();
              }}
            >
              {optionAllText}
            </button>
          )
        }

        {menuListProps.children}

      </components.MenuList>

      {
        isMulti && (
          <button
            className="button button--cancel filter-select__button"
            onClick={() => window.document.activeElement.blur()}
            tabIndex={-1}
          >
            Ok
          </button>
        )
      }

    </>
  );
};

MenuList.propTypes = {
  selectProps: PropTypes.shape({
    onClick: PropTypes.func,
    filterStatus: PropTypes.arrayOf(PropTypes.shape({})),
    optionAllText: PropTypes.string,
  }),
};

MenuList.defaultProps = {
  selectProps: {
    optionAllText: 'All',
  },
};


const MultiValue = (props) => {
  if (props.data.chipLabel || props.data.label) {
    return (
      <components.MultiValue {...props}>
        {props.data.chipLabel ? props.data.chipLabel : props.data.label}
      </components.MultiValue>
    );
  }

  return (
    <components.MultiValue {...props} />
  );
};

const StyledContainer = styled.div`
  ${({ minWidth, isActive, hasValue }) => isActive ? '' : `
    .filter-select {
      min-width: ${hasValue ? 'auto' : minWidth};
    }`}
  ${({ cssRules }) => cssRules && css`${cssRules}`}
`;

const selectComponents = { MenuList, MultiValue };

const Select = ({
  items,
  styles,
  filters,
  cssRules,
  onChange,
  selected,
  withChips,
  isMultiple,
  placeholder,
  filterOption,
  getOptionValue,
  getOptionLabel,
  isFetchingData,
  closeMenuOnSelect,
  placeholderLength,
  loadingPlaceholder,
  optionAllText,
  isActive,
}) => {
  const [selectedTerms, setSelectedTerms] = useState(selected);
  const [filteredItems, filterItems] = useState(items);
  const [filterStatus, setCheck] = useState(filters);

  const hasFilters = filters.length;
  const onCheck = useCallback((name) => {
    const updatedFiltersStatus = filterStatus.map((item) => {
      if (item.name === name) {
        return update(item, {
          isActive: { $set: !item.isActive },
        });
      }
      return item;
    });

    setCheck(updatedFiltersStatus);
  }, [filterStatus]);

  const onSelect = useCallback((values) => {
    const isFilterCleared = isMultiple && (
      !values ||
      !values.length ||
      values.length === items.length
    );

    if (isFilterCleared) {
      window.document.activeElement.blur();
    }

    const currentSelectedTerms = isFilterCleared && items.length !== 1 ? [] : values;
    setSelectedTerms(currentSelectedTerms);
  }, [isMultiple, items, setSelectedTerms]);

  const applyFilters = (selectItems) => {
    filterStatus.forEach((item) => {
      if (item.isActive) {
        filterItems(item.onChange(selectItems));
      } else {
        filterItems(items);
      }
    });
  };

  const options = useMemo(() => hasFilters ? filteredItems : items, [hasFilters, filteredItems, items]);

  useEffect(() => {
    applyFilters(items);
  }, [filterStatus, items]);

  useEffect(() => {
    if (selected !== selectedTerms) {
      onChange(selectedTerms);
    }
  }, [selectedTerms]);

  useEffect(() => {
    if (selected !== selectedTerms) {
      setSelectedTerms(selected);
    }
  }, [selected]);

  useEffectOnlyOnUpdate(() => {
    if (!isActive && selectedTerms.length) {
      setSelectedTerms([]);
    }
  }, [isActive]);

  const isSelectActive = selectedTerms && selectedTerms.length;
  const currentPlaceholder = isFetchingData ? loadingPlaceholder : placeholder;
  const minSelectWidth = isFetchingData ? '14ch' : placeholderLength;
  const hasValue = selectedTerms ? !!selectedTerms.value : false;

  return (
    <StyledContainer
      className={
        classnames(
          'select',
          { 'select--is-multiple': isMultiple },
          { 'select--with-chips': withChips },
          { 'select--with-line': !withChips },
        )
      }
      minWidth={minSelectWidth}
      isActive={isSelectActive}
      hasValue={hasValue}
      cssRules={cssRules}
    >
      <ReactSelect
        isSearchable
        styles={styles}
        onClick={onCheck}
        onChange={onSelect}
        isClearable={false}
        isMulti={isMultiple}
        value={selectedTerms}
        tabSelectsValue={false}
        components={selectComponents}
        className="filter-select"
        isLoading={isFetchingData}
        filterOption={filterOption}
        isDisabled={isFetchingData}
        hideSelectedOptions={false}
        filterStatus={filterStatus}
        classNamePrefix="filter-select"
        closeMenuOnSelect={closeMenuOnSelect || !isMultiple}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        placeholder={currentPlaceholder}
        options={options}
        optionAllText={optionAllText}
      />

    </StyledContainer>
  );
};

Select.propTypes = {
  styles: PropTypes.shape({}),
  isFetchingData: PropTypes.bool,
  withChips: PropTypes.bool,
  onChange: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.shape(),
  ])),
  filters: PropTypes.arrayOf(PropTypes.shape()),
  isMultiple: PropTypes.bool,
  selected: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    })),
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ]),
  cssRules: PropTypes.string,
  filterOption: PropTypes.func,
  placeholder: PropTypes.string,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  closeMenuOnSelect: PropTypes.bool,
  placeholderLength: PropTypes.string,
  loadingPlaceholder: PropTypes.string,
  optionAllText: PropTypes.string,
  isActive: PropTypes.bool,
};

Select.defaultProps = {
  items: [],
  styles: {},
  selected: [],
  filters: [],
  isFetchingData: false,
  isMultiple: false,
  withChips: true,
  onChange: () => null,
  filterOption: undefined,
  getOptionLabel: (option) => option,
  getOptionValue: (option) => option,
  cssRules: '',
  placeholder: 'All',
  placeholderLength: '',
  closeMenuOnSelect: false,
  loadingPlaceholder: 'Loading',
  optionAllText: 'All',
  isActive: false,
};

export default Select;
