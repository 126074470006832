import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import Calendar from 'components/calendar';
import Controls from 'components/details-controls';
import NavigationTabs from 'components/details-tabs';
import FilterSelect from 'components/filter-select';

import { USERS_GROUPS } from 'core/auth/constants';
import { selectDevCenters, selectBillingAgents, selectDevCentersBySystemRoleAuditor } from 'core/auth/selectors';

import { REPORTS_TYPES } from 'core/billing-reports/constants';
import {
  selectSelectedDevCenter,
  selectBillingSummaryReportsDetails,
} from 'core/billing-reports/selectors';
import pageSlugs from 'core/config/pages-slugs';
import ActionButton from 'elements/action-button';
import GoTop from 'elements/go-top-button';
import StyledWrapper from 'elements/styled-wrapper';
import LayoutBodyContent from 'layouts/layout-body';
import { getDevCenterId } from 'layouts/summary-reports/model/utils';
import { get } from 'lodash';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { createStructuredSelector } from 'reselect';

import { updateState } from 'utils/helpers/history';
import { isAudit as isAuditHelper } from 'utils/helpers/isAudit';
import { autoScrollToTop } from 'utils/react';

const {
  DELIVERY_OFFICE_ADMIN,
  ACC_WAW,
  ACC_TBS,
} = USERS_GROUPS;
const { getTabName, getIndex } = pageSlugs.summaryReports;

const BillingSummaryReports = ({
  location,
  userGroup,
  tab,
  isFetching,
  devCenters,
  entityName,
  orderRules,
  reportType,
  changeOrder,
  changeFilter,
  resetFilters,
  billingAgents,
  selectedReportDate,
  setSummaryReportType,
  setBillingSummaryReportData,
  billingSummaryReportsDetails,
  selectedDevCenter,
  generateBillingProjectExcelReport,
  devCentersBySystemRoleAuditor,
}) => {
  autoScrollToTop(location);

  const isAccWaw = ACC_WAW === userGroup;
  const isAccTbs = ACC_TBS === userGroup;

  const isAudit = isAuditHelper(userGroup);
  const getUserDevcenter = () => {
    if (isAccWaw) {
      return getDevCenterId(devCenters.byIdShortName, 'POL');
    }
    if (isAccTbs) {
      return getDevCenterId(devCenters.byIdShortName, 'TBS');
    }
    if (isAudit) {
      const result = devCentersBySystemRoleAuditor.find((item) => USERS_GROUPS[item.role] === userGroup);
      return get(result, 'devcenterList[0]', undefined);
    }
  };

  const userDevcenter = getUserDevcenter();
  const initialTab = getIndex(tab);
  const [indexOfActiveTab, setIndexOfActiveTab] = useState(initialTab);
  const displayedDate = useMemo(
    () => selectedReportDate.format('MMMM YYYY'),
    [selectedReportDate]
  );
  const {
    controls,
    tabHeadings,
    tabData = [],
    selectCssRules,
    controlsHeading,
    calendarCssRules,
    controlsCssRules,
    tabStylesTemplates,
    calendarItemCssRules,
    controlsWrapperCssRules,
    controlButtonStyles,
  } = billingSummaryReportsDetails;
  const actions = {
    resetFilters,
  };

  useEffect(() => {
    setIndexOfActiveTab(initialTab);

    setSummaryReportType(reportType);
  }, [reportType]);

  useEffect(() => {
    if (userDevcenter) {
      setBillingSummaryReportData({
        devcenterIds: [userDevcenter],
      });
    } else {
      setBillingSummaryReportData({
        devcenterIds: [],
      });
    }
  }, [isAudit, userDevcenter]);

  const setTabIndex = (index) => {
    if (!isFetching) {
      updateState({ tabIndex: index }, getTabName(index));
      setIndexOfActiveTab(index);
    }
  };
  const setFilter = (selectedItems) => {
    const itemsIds = selectedItems.map((item) => item.value);

    const reportData =
      REPORTS_TYPES.DTO === reportType ?
        {
          devcenters: selectedItems,
          devcenterIds: itemsIds,
        } :
        {
          billingAgentIds: itemsIds,
        };
    setBillingSummaryReportData(reportData);
  };

  const setDate = (selectedDate) => {
    setBillingSummaryReportData({
      selectedDate,
    });
  };

  const { items, placeholder, placeholderLength } =
    REPORTS_TYPES.DTO === reportType ?
      {
        items: devCenters.forSelect,
        placeholder: 'All Delivery Centers',
        placeholderLength: '18rem',
      } :
      {
        items: billingAgents,
        placeholder: 'All Billing Agents',
        placeholderLength: '15.3rem',
      };

  const layoutProps = {
    changeOrder,
    orderRules,
    entityName,
    isFetching,
    resetFilters,
    changeFilter,
  };

  const {
    selectedDevCenterIds,
    isExcelAvailable,
    isExcelDisabled,
    deliveryCenterId,
  } = useMemo(() => {
    if (isAudit || isAccWaw || isAccTbs) {
      return {
        selectedDevCenterIds: [userDevcenter],
        isExcelAvailable: true,
        isExcelDisabled: true,
        deliveryCenterId: userDevcenter,
      };
    }
    const ids =
      selectedDevCenter != null ?
        selectedDevCenter.map(({ value }) => value) :
        [];

    // We need only one delivery center at once.
    const isOneDevCenterSelected = ids.length === 1;
    const isAvailable = userGroup === DELIVERY_OFFICE_ADMIN;
    // eslint-disable-next-line no-bitwise

    return {
      selectedDevCenterIds: ids,
      isExcelAvailable: isAvailable,
      isExcelDisabled: isOneDevCenterSelected,
      deliveryCenterId: ids[0],
    };
  }, [selectedDevCenter, isAudit, devCentersBySystemRoleAuditor]);

  const onClickExcel = useCallback(() => {
    generateBillingProjectExcelReport({
      month: selectedReportDate.month() + 1,
      year: selectedReportDate.year(),
      deliveryCenterId: (isAccWaw || isAccTbs) ?
        userDevcenter :
        deliveryCenterId,
    });
  }, [selectedReportDate, selectedDevCenterIds, userDevcenter]);

  return (
    <>
      <Controls
        controlsHeading={controlsHeading}
        cssRules={
          isExcelAvailable ?
            `
              ${controlsCssRules}
              &&& {
                grid-template-columns: 1fr minmax(auto,15.2rem);
              }
            ` :
            controlsCssRules
        }
      >
        {controls && (
          <>
            {controls.map(({ title: buttonTitle, actionName }) => (
              <button
                key={buttonTitle}
                className="controls__create-button button button--cancel client-details-controls__button--fixed-size"
                onClick={() => actions[actionName]()}
              >
                {buttonTitle}
              </button>
            ))}
          </>
        )}

        {isExcelAvailable && (
          <ActionButton
            data="Excel"
            withBody
            onClick={onClickExcel}
            cssRules={controlButtonStyles}
            disabled={!isExcelDisabled}
          />
        )}

        <StyledWrapper cssRules={controlsWrapperCssRules}>
          <Calendar
            withStepControls
            withMonthSelecting
            minDetails="year"
            onChange={setDate}
            stepControlsConfig={{
              step: 1,
              unitName: 'month',
            }}
            cssRules={calendarCssRules}
            value={selectedReportDate}
            popperProps={{
              placement: 'bottom-start',
            }}
          >
            <StyledWrapper cssRules={calendarItemCssRules}>
              {displayedDate}
            </StyledWrapper>
          </Calendar>
          {REPORTS_TYPES.GM !== reportType && !isAudit && (
            <FilterSelect
              key={reportType}
              cssRules={selectCssRules}
              isMultiple
              withChips={false}
              items={items}
              getOptionValue={(option) => option.value}
              getOptionLabel={(option) => option.label}
              onChange={setFilter}
              placeholder={placeholder}
              placeholderLength={placeholderLength}
            />
          )}
        </StyledWrapper>
      </Controls>

      <NavigationTabs
        indexOfActiveTab={indexOfActiveTab}
        setIndexOfActiveTab={setTabIndex}
        tabHeadings={tabHeadings}
      />
      {tabData.map((body, index) => {
        const isVisible = index === indexOfActiveTab;
        const { wrapperCssRules } = tabStylesTemplates[index];
        return (
          <LayoutBodyContent
            key={index} // eslint-disable-line react/no-array-index-key
            body={body}
            isVisible={isVisible}
            wrapperCssRules={wrapperCssRules}
            unitActions={actions}
            {...layoutProps}
          />
        );
      })}
      <GoTop />
    </>
  );
};

BillingSummaryReports.propTypes = {
  location: PropTypes.object.isRequired,
  userGroup: PropTypes.string.isRequired,
  selectedReportDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(momentTZ),
  ]).isRequired,
  billingAgents: PropTypes.arrayOf(PropTypes.shape).isRequired,
  reportType: PropTypes.string,
  setBillingSummaryReportData: PropTypes.func.isRequired,
  changeOrder: PropTypes.func.isRequired,
  orderRules: PropTypes.shape({}).isRequired,
  devCenters: PropTypes.shape({}).isRequired,
  entityName: PropTypes.string.isRequired,
  billingSummaryReportsDetails: PropTypes.shape({
    tabStylesTemplates: PropTypes.array,
    tabHeadings: PropTypes.array,
    tabData: PropTypes.array,
  }).isRequired,
  tab: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  resetFilters: PropTypes.func.isRequired,
  changeFilter: PropTypes.func.isRequired,
  setSummaryReportType: PropTypes.func.isRequired,
};

BillingSummaryReports.defaultProps = {
  tab: '',
  reportType: '',
};

const mapStateToProps = createStructuredSelector({
  devCenters: selectDevCenters,
  billingAgents: selectBillingAgents,
  selectedDevCenter: selectSelectedDevCenter,
  devCentersBySystemRoleAuditor: selectDevCentersBySystemRoleAuditor,
  billingSummaryReportsDetails: selectBillingSummaryReportsDetails,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingSummaryReports);
