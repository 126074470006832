import { get, isEmpty } from 'lodash';

export const validationMessage = (value = '', path) => `Category ${path} "${value}" already exists.`;

const isUnique = (condition, value, context) => condition ? context.createError({ message: validationMessage(value, context.type) }) : true;

export function uniqueNameValidator(value) {
  const {
    categories,
    initialCategories,
    hiqoCategoriesNames,
    categoriesNames,
  } = this.options.context;
  const lowerCaseValue = value.toLowerCase();
  const listInitialNames = initialCategories.map(({ name }) => name.toLowerCase());
  const listFieldsNames = categories.map(({ name }) => name.toLowerCase());
  const getCategoryIndex = Number(this.path.replace(/\D/g, ''));
  const hasDifferentName = listFieldsNames[getCategoryIndex] !== listInitialNames[getCategoryIndex];
  const hasDifferentIndex = getCategoryIndex !== listFieldsNames.indexOf(lowerCaseValue);

  if (!categories[getCategoryIndex].isCreated) {
    return isUnique(hasDifferentIndex && listFieldsNames.includes(lowerCaseValue), value, this);
  }

  if (isEmpty(hiqoCategoriesNames)) {
    return isUnique(hasDifferentName && categoriesNames.includes(lowerCaseValue), value, this);
  }

  return isUnique(hasDifferentName && hiqoCategoriesNames.includes(lowerCaseValue), value, this);
}

export function uniqueKeyValidator(value) {
  const {
    categories,
    initialCategories,
    categoriesKeys,
    client,
    type,
  } = this.options.context;

  let excludedCategories;
  let additionalCategories;

  if (client.isInitial) {
    excludedCategories = [];
    additionalCategories = [];
  } else {
    excludedCategories = type === 'Client' ? get(client, 'availableInternalCategories', []) : get(client, 'availableClientCategories', []);
    additionalCategories = type === 'Client' ? get(client, 'availableClientCategories', []) : get(client, 'availableInternalCategories', []);
  }

  const excludedKeys = excludedCategories.map((item) => item.key);
  const additionalKeys = additionalCategories.map((item) => item.key);
  const filteredKeys = categoriesKeys.filter((key) => !excludedKeys.includes(key));

  const getCategoryIndex = Number(this.path.replace(/\D/g, ''));
  const hasDifferentKey = get(categories[getCategoryIndex], 'key', '') !== get(initialCategories[getCategoryIndex], 'key', '');

  return isUnique(hasDifferentKey && [...filteredKeys, ...additionalKeys].includes(value), value, this);
}
