import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import StyledWrapper from 'elements/styled-wrapper';

import './styles.scss';

const ScreenPlaceholder = ({ title, description, cssRules, content, isFull }) => (
  <StyledWrapper className="screen-placeholder" cssRules={cssRules}>
    {title && <p className="screen-placeholder__title">{title}</p>}

    <p
      className={classNames('screen-placeholder__description', {
        'screen-placeholder__description--full': isFull,
      })}
    >{description}
    </p>

    {content && <p className="screen-placeholder__controls">{content}</p>}
  </StyledWrapper>
);

ScreenPlaceholder.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  cssRules: PropTypes.string,
  content: PropTypes.any,
  isFull: PropTypes.bool,
};

ScreenPlaceholder.defaultProps = {
  title: '',
  cssRules: '',
  description: '',
  content: '',
  isFull: false,
};

export default ScreenPlaceholder;
