import React, { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';

import { ActionButton } from 'components/buttons';
import Controls from 'components/list-controls';

import { filesActions } from 'core/files/actions';
import ScreenPlaceholder from 'elements/screen-placeholder';

import { TEXTS } from './locales/en';
import styles from './style.module.scss';

const { generatePtoReport } = filesActions;

export const PtoReport = () => {
  const dispatch = useDispatch();
  const generateReport = useCallback(() => {
    dispatch(generatePtoReport());
  }, []);

  const content = useMemo(() => (
    <ActionButton
      dataId="generate-report"
      type="button"
      onClick={generateReport}
      className={styles.excelButton}
    >
      {TEXTS.buttonTitle.toUpperCase()}
    </ActionButton>
  ), []);

  return (
    <>
      <Controls
        title={TEXTS.controlsTitle}
        className={styles.controls}
      />
      <ScreenPlaceholder
        description={TEXTS.description}
        content={content}
        isFull
      />
    </>
  );
};
