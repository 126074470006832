import React, {
  useMemo,
} from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import Calendar from 'components/calendar';
import Controls from 'components/details-controls';
import FilterSelect from 'components/filter-select';
import units from 'components/shared-units';

import { selectDevCenters } from 'core/auth/selectors';
import { billingReportsActions } from 'core/billing-reports/actions';
import { STORE_KEYS } from 'core/billing-reports/constants';
import {
  selectCurrentPayrollReportData,
  selectSelectedDevCenter,
  selectCurrentPayrollReportDetails,
} from 'core/billing-reports/selectors';

import { filesActions } from 'core/files/actions';
import GoTop from 'elements/go-top-button';
import ScreenPlaceholder from 'elements/screen-placeholder';
import StyledWrapper from 'elements/styled-wrapper';
import { get } from 'lodash';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { createStructuredSelector } from 'reselect';
import { autoScrollToTop } from 'utils/react';

import './style.scss';

const CurrentPayrollReport = ({
  setBillingProjectReportData,
  location,
  devCenters,
  changeFilter,
  resetFilters,
  selectedReportDate,
  setCurrentPayrollReportData,
  currentPayrollReportData,
  currentPayrollReportDetails,
  generateCurrentPayrollReport,
  generateCurrentPayrollExcelReport,
}) => {
  autoScrollToTop(location);

  const {
    controls,
    selectCssRules,
    controlsHeading,
    controlsCssRules,
    staffFilterItems,
    calendarCssRules,
    calendarItemCssRules,
    controlsWrapperCssRules,
  } = currentPayrollReportDetails;

  const displayedDate = useMemo(() => selectedReportDate.format('MMMM YYYY'), [selectedReportDate]);

  const currentPayrollReportArguments = useMemo(() => {
    const { devcenter, staff } = currentPayrollReportData;
    const selectedDate = selectedReportDate.format('l').split('/');

    const actionArguments = {
      month: selectedDate[0],
      year: selectedDate[2],
    };

    if (devcenter && devcenter.length) {
      actionArguments.devcenterIds = devcenter;
    }
    if (staff) {
      actionArguments.isDelivery = staff === STORE_KEYS.DEV_STAFF;
    }

    return actionArguments;
  }, [currentPayrollReportData, displayedDate]);

  const actions = {
    resetFilters,
    changeFilter,
    generateCurrentPayrollReport: () => generateCurrentPayrollReport(currentPayrollReportArguments),
    generateCurrentPayrollExcelReport: () => generateCurrentPayrollExcelReport(currentPayrollReportArguments),
  };

  const onSelectStaff = (selectedStaff) => {
    const selected = get(selectedStaff, 'value', null);

    setCurrentPayrollReportData({
      staff: selected,
    });
  };

  const onSelectDevcenter = (selectedDevcenters) => {
    const selected = selectedDevcenters.map((devCenter) => get(devCenter, 'value', null));
    setCurrentPayrollReportData({
      devcenter: selected,
    });
  };

  const setDate = (selectedDate) => {
    setCurrentPayrollReportData({ selectedDate });
    setBillingProjectReportData({ selectedDate });
  };

  const controlButtons = controls.map((control, index) => {
    if (control && control.show) {
      const { type, onClick, ...rest } = control;

      const action = (actionArguments) => onClick({
        actions,
        actionArguments,
      });

      return (
        React.createElement(
          units[type],
          {
            // eslint-disable-next-line react/no-array-index-key
            key: index,
            onClick: action,
            ...rest,
          },
        )
      );
    }
    return null;
  });

  return (
    <>
      <Controls
        controlsHeading={controlsHeading}
        cssRules={controlsCssRules}
      >
        <StyledWrapper
          cssRules={controlsWrapperCssRules}
        >
          <Calendar
            withStepControls
            withMonthSelecting
            onChange={setDate}
            minDetails="year"
            currentDate={moment()}
            maxYear={moment().year() + 1}
            onYearClick={setDate}
            cssRules={calendarCssRules}
            value={moment(selectedReportDate)}
            stepControlsConfig={{
              step: 1,
              unitName: 'month',
            }}
            popperProps={{
              placement: 'bottom-start',
            }}
          >
            <StyledWrapper
              cssRules={calendarItemCssRules}
            >
              {displayedDate}
            </StyledWrapper>
          </Calendar>

          <FilterSelect
            cssRules={selectCssRules}
            isMultiple
            withChips={false}
            items={devCenters.forSelect}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            onChange={onSelectDevcenter}
            placeholder="All Delivery Centers"
            placeholderLength="17.5rem"
          />

          <FilterSelect
            cssRules={selectCssRules}
            withChips={false}
            items={staffFilterItems}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            onChange={onSelectStaff}
            placeholder="All Staff"
            placeholderLength="9rem"
          />
        </StyledWrapper>
      </Controls>
      <ScreenPlaceholder
        description="Please select Month and Year, Delivery Center and then generate a report."
        content={controlButtons}
        isFull
      />
      <GoTop />
    </>
  );
};

CurrentPayrollReport.propTypes = {
  location: PropTypes.object.isRequired,
  selectedReportDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(momentTZ),
  ]).isRequired,
  setBillingSummaryReportData: PropTypes.func.isRequired,
  setCurrentPayrollReportData: PropTypes.func.isRequired,
  changeOrder: PropTypes.func.isRequired,
  orderRules: PropTypes.shape({}).isRequired,
  devCenters: PropTypes.shape({}).isRequired,
  entityName: PropTypes.string.isRequired,
  currentPayrollReportDetails: PropTypes.shape({
    tabStylesTemplates: PropTypes.array,
    tabHeadings: PropTypes.array,
    tabData: PropTypes.array,
  }).isRequired,
  tab: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  resetFilters: PropTypes.func.isRequired,
  changeFilter: PropTypes.func.isRequired,
  setSummaryReportType: PropTypes.func.isRequired,
  generateCurrentPayrollReport: PropTypes.func.isRequired,
  setBillingProjectReportData: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  devCenters: selectDevCenters,
  currentPayrollReportData: selectCurrentPayrollReportData,
  selectedDevCenter: selectSelectedDevCenter,
  currentPayrollReportDetails: selectCurrentPayrollReportDetails,
});

const mapDispatchToProps = {
  setCurrentPayrollReportData: billingReportsActions.setCurrentPayrollReportData,
  generateCurrentPayrollReport: filesActions.generateCurrentPayrollReport,
  generateCurrentPayrollExcelReport: filesActions.generateCurrentPayrollExcelReport,
  setBillingProjectReportData: billingReportsActions.setBillingProjectReportData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentPayrollReport);
